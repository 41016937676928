import styled from 'styled-components';

import {
  StyledNovelty,
  StyledHeaderInformation as HeaderInformation
} from '../Novelty.styled';

export const StyledRectangularNovelty = styled(StyledNovelty)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover {
    & p {
      font-weight: bold;
    }
    & h4 {
      font-weight: bold !important;
    }
  }
`;

export const StyledHeaderInformation = styled(HeaderInformation)`
  display: flex;
  width: max-content;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledHeaderItem = styled.div`
  margin-right: ${({ theme }) => theme.utils.spacing(4)};
`;

export const TitleContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.utils.spacing(3)};
  width: 78%;
`;
