import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useConfig from '@hooks/useConfig';
import { languages } from '@config/constants';

const useDelay = (delay = 15, isOnline = false) => {
  const { t } = useTranslation();
  const [{ language }] = useConfig();

  const delayText = useMemo(() => {
    if (language === languages.ES) {
      return `${t('delay')} ${delay} ${t('minutes')}`;
    }
    return `${delay} ${t('minutes')} ${t('delay')}`;
  }, [language, delay, t]);

  return isOnline ? t('online') : delayText;
};

export default useDelay;
