import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@bvcco/bvc-digital-market-component-library';

import { formatDecimal } from '@utils/numbers';
import { StyledRow, StyledCol } from '../../Header.styled';

const Header = ({
  index,
  previousClosing,
  showMarketStatus,
  isMoneyMarket,
  marketIsOpen = false
}) => {
  const { t } = useTranslation();

  return (
    <StyledRow>
      <StyledCol xs={6}>
        {index && (
          <>
            <Typography color='tertiary' type='caption' fontWeight='900' as='p'>
              {index?.toUpperCase()}
            </Typography>
            <Typography color='quaternary' type='caption' as='p'>
              {t('previousClosing')}:{' '}
              {formatDecimal(previousClosing, {
                separator: ',',
                decimals: isMoneyMarket ? 4 : 2
              })}
            </Typography>
          </>
        )}
      </StyledCol>
      <StyledCol xs={6}>
        {showMarketStatus && (
          <>
            <Typography
              textAlign='right'
              fontWeight='900'
              color={marketIsOpen ? 'success' : 'danger'}
              type='caption'
              as='p'
            >
              {marketIsOpen ? t('openMarket') : t('closedMarket')}
            </Typography>
            <Typography
              textAlign='right'
              color='tertiary'
              type='caption'
              as='p'
            >
              {t('online')}
            </Typography>
          </>
        )}
      </StyledCol>
    </StyledRow>
  );
};

Header.propTypes = {
  marketIsOpen: PropTypes.bool.isRequired,
  index: PropTypes.string.isRequired,
  previousClosing: PropTypes.number.isRequired,
  showMarketStatus: PropTypes.bool.isRequired,
  isMoneyMarket: PropTypes.bool.isRequired
};

export default Header;
