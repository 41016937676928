import styled from 'styled-components';

export const StyledContainerBanner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 12% 4%;

  .description-text {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }

  @media screen and (max-width: 768px) {
    margin: 6% 4% 10% 4%;

    .description-text {
      font-size: 16px;
      padding: 0 3rem; 
    }    
  }

`;