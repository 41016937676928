import PropTypes from 'prop-types';
import {
  Typography,
  LightWeightCharts,
  LightWeightChartsN
} from '@bvcco/bvc-digital-market-component-library';

import Header from '../Header/Header';
import { formatDecimal } from '@utils/numbers';
import { StyledRow, StyledCol } from '../../Chart.styled';
import useChart from '@hooks/local-market/level-1/useChart';
import { TOOLTIP_CHART_DIMENSIONS } from '@config/constants';

const Chart = ({ data, headerProps, mnemonic }) => {
  const { t, selectedData, getDateFormatter } = useChart({ data, mnemonic });

  const renderTooltip = (content) => {
    if (!selectedData) {
      return null;
    }
    const point = selectedData.points.find(
      ({ xAxis }) => xAxis === content.originalDate
    );
    return (
      <>
        <Typography
          color='secondaryChar'
          colorType='light'
          fontWeight='bold'
          type='caption'
        >
          {t('hour')}
        </Typography>
        <Typography
          color='charTooltipValue'
          colorType='light'
          type='paragraph3'
        >
          {content.date}
        </Typography>
        <Typography
          color='secondaryChar'
          colorType='light'
          fontWeight='bold'
          type='caption'
        >
          {t('closingPrice')}
        </Typography>
        <Typography
          color='charTooltipValue'
          colorType='light'
          type='paragraph3'
        >
          {formatDecimal(content.value, { separator: '.', decimals: 2 })}
        </Typography>
        <Typography
          color='secondaryChar'
          colorType='light'
          fontWeight='bold'
          type='caption'
        >
          {t('volume')}
        </Typography>
        <Typography
          color='charTooltipValue'
          colorType='light'
          type='paragraph3'
        >
          {formatDecimal(point?.y2Axis, { decimals: 2 })}
        </Typography>
      </>
    );
  };

  const manageRangeAndSeries = () => {
    const mnemoni = data.find((item) => item.mnemonic === mnemonic);
    if (data.length > 0 && mnemonic && mnemoni) {
      const lastArray = [
        {
          type: 'linear',
          content: {
            data: mnemoni.points.map((item) => ({
              time: parseInt(item.xAxis),
              value: item.y1Axis
            }))
          }
        },
        {
          type: 'histogram',
          content: {
            data: mnemoni.points.map((item) => ({
              time: parseInt(item.xAxis),
              value: item.y2Axis
            })),
            settings: {
              priceScaleId: 'left',
              color: '#344753'
            }
          }
        }
      ];
      return {
        dataAux: lastArray,
        fromToDate: last50Elements(lastArray)
      };
    }

    return {
      dataAux: [],
      fromToDate: []
    };
  };

  const last50Elements = (objectFilter) => {
    const fromToDate = {};
    const firstSerie = objectFilter[0].content.data;
    if (firstSerie.length > 50) {
      fromToDate.from = parseInt(firstSerie[firstSerie.length - 50].time);
      fromToDate.to = parseInt(firstSerie[firstSerie.length - 1].time);
      return fromToDate;
    }

    return {
      from: 0,
      to: 0
    };
  };
  const { dataAux, fromToDate } = manageRangeAndSeries();
  return (
    <>
      {mnemonic && data.find((item) => item.mnemonic === mnemonic) ? (
        <StyledRow>
          <StyledCol xs={false} md={12}>
            <Header {...headerProps} />
          </StyledCol>
          <StyledCol xs={12}>
            <LightWeightChartsN
              dateFormat={getDateFormatter()}
              tooltipConfig={{
                show: true,
                bgColor: 'chartTooltip',
                dimensions: TOOLTIP_CHART_DIMENSIONS.MEDIUM_LARGE,
                renderContent: renderTooltip,
                seriesPosition: 0
              }}
              priceLine={headerProps.previousClosing}
              data={dataAux}
              fromToDate={fromToDate}
            />
          </StyledCol>
        </StyledRow>
      ) : null}
    </>
  );
};

Chart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  headerProps: PropTypes.shape(Header.propTypes).isRequired,
  mnemonic: PropTypes.string.isRequired
};

export default Chart;
