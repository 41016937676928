import { Typography } from '@bvcco/bvc-digital-market-component-library';

import {
  TitleContainer,
  StyledHeaderItem,
  StyledSquareNovelty,
  StyledHeaderInformation
} from './SquareNovelty.styled';
import getFormattedDate from '../getFormattedDate';
import { propTypes, defaultProps } from '../noveltyPropTypes';

const SquareNovelty = ({
  id,
  author,
  title,
  subtitle,
  dateAndTime,
  ...rest
}) => (
  <StyledSquareNovelty {...rest}>
    <StyledHeaderInformation>
      <StyledHeaderItem>{getFormattedDate(dateAndTime, rest)}</StyledHeaderItem>
      <StyledHeaderItem>
        <Typography type='paragraph3' color='primary' colorType='light'>
          {author}
        </Typography>
      </StyledHeaderItem>
    </StyledHeaderInformation>
    <div>
      <TitleContainer>
        <Typography as='h4' type='h6' color='primary' colorType='light'>
          {title}
        </Typography>
      </TitleContainer>
      <Typography
        color='primary'
        textAlign='left'
        type='paragraph3'
        colorType='light'
      >
        {subtitle}
      </Typography>
    </div>
  </StyledSquareNovelty>
);

SquareNovelty.propTypes = {
  id: propTypes.id.isRequired,
  mainImage: propTypes.mainImage,
  title: propTypes.title.isRequired,
  dateAndTime: propTypes.dateAndTime,
  author: propTypes.author.isRequired,
  subtitle: propTypes.subtitle.isRequired
};

SquareNovelty.defaultProps = {
  mainImage: defaultProps.mainImage,
  dateAndTime: defaultProps.dateAndTime
};

export default SquareNovelty;
