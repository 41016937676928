import { useMemo } from 'react';
import { formatDecimal } from '@utils/numbers';
import { properties } from '@config/constants';
import { removeDashMnemonic } from '@utils/strings';

const mapperRFPub = (data) => {
  let cleanAndMappingData = data.filter((row) => row.volume > 0);

  cleanAndMappingData = cleanAndMappingData.map((row, index) => ({
    tableId: row.mnemonic + index,
    mnemonic: removeDashMnemonic(row.mnemonic),
    volume: formatDecimal(row.volume, {
      separator: ',',
      decimal: '.',
      decimals: 2
    }),
    lastPrice: row.lastPrice
      ? formatDecimal(row.lastPrice, {
          separator: ',',
          decimal: '.',
          decimals: 3
        })
      : row.lastPrice,
    lastRate:
      row.lastRate !== 'null' && row.lastRate !== 'undefined'
        ? formatDecimal(row.lastRate, {
            separator: ',',
            decimal: '.',
            decimals: 3
          })
        : row.lastRate,
    variation:
     typeof row.variation !== 'string' && row.variation !== 0
        ? formatDecimal(row.variation, {
            separator: ',',
            decimal: '.',
            decimals: 2
          })
        : row.variation,
    priceOrYield: row.priceOrYield,
    referenceRate: row.referenceRate ?? row.referenceYield,
    fullMnemonic: row.mnemonic
  }));
  
  return cleanAndMappingData;
}
  
const mapperRfPriv = (data) => {
  let cleanAndMappingData = data.filter((row) => row.volume > 0 && row.numberOfOperations > 0);

  cleanAndMappingData = cleanAndMappingData.map((row, index) => ({
    tableId: row.titleClass + index,
    titleClass: row.titleClass,
    volume: formatDecimal(row.volume, {
      separator: ',',
      decimal: '.',
      decimals: 2
    }),
    numberOfOperations: row.numberOfOperations
  }));

  return cleanAndMappingData;
}

const useFixedRentTable = (data, currentTab) => {
  const formatData = useMemo(
    () =>
      currentTab === properties.rf.most_traded_public_debt
        ? mapperRFPub(data)
        : mapperRfPriv(data),
    [data, currentTab]
  );

  return formatData;
};

export default useFixedRentTable;
