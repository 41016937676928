import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@bvcco/bvc-digital-market-component-library';

import { formatDecimal } from '@utils/numbers';
import useDelay from '@hooks/local-market/useDelay';
import { StyledRow, StyledCol } from '../../Header.styled';
import { useMemo } from 'react';

const Header = ({ delay, referenceRate, marketIsOpen, mnemonic, title, priceOrYield }) => {
  const { t } = useTranslation();
  const delayText = useDelay(delay);

  const descriptionTextReference = priceOrYield == 1 ? t('referencePrice') : t('referenceRate'); 

  const headers = useMemo(
    () => (
      <StyledRow>
        <StyledCol xs={6}>
          <>
            <Typography color='tertiary' type='caption' fontWeight='900' as='p'>
              {mnemonic?.toUpperCase() || ''}
            </Typography>
            <Typography color='quaternary' type='caption' as='p'>
              {`${descriptionTextReference}: `}
              {formatDecimal(referenceRate, { separator: ',', decimals: 2 })}
            </Typography>
          </>
        </StyledCol>
        <StyledCol xs={6}>
          <Typography
            textAlign='right'
            fontWeight='900'
            color={marketIsOpen ? 'success' : 'danger'}
            type='caption'
            as='p'
          >
            {marketIsOpen ? t('openMarket') : t('closedMarket')}
          </Typography>
          <Typography textAlign='right' color='tertiary'   type='caption' as='p'>
            {delayText}
          </Typography>
        </StyledCol>
      </StyledRow>
    ),
    [title, referenceRate]
  );
  return <>{headers}</>;
};

Header.propTypes = {
  delay: PropTypes.string.isRequired,
  marketIsOpen: PropTypes.bool.isRequired,
  mnemonic: PropTypes.string,
  referenceRate: PropTypes.number,
  title: PropTypes.string
};

Header.defaultProps = {
  mnemonic: '',
  referenceRate: undefined,
  title: ''
};

export default Header;
