/* eslint-disable */

import { useState, useEffect } from 'react';

const useBrowser = () => {
  // Opera 8.0+
  const [isOpera, setIsOpera] = useState(false);
  // Firefox 1.0+
  const [isFirefox, setIsFirefox] = useState(false);
  // Safari 3.0+ "[object HTMLElementConstructor]"
  const [isSafari, setIsSafari] = useState(false);
  // Internet Explorer 6-11
  const [isIE, setIsIE] = useState(false);
  // Edge 20+
  const [isEdge, setIsEdge] = useState(false);
  // Chrome 1 - 79
  const [isChrome, setIsChrome] = useState(false);
  // Edge (based on chromium) detection
  const [isEdgeChromium, setIsEdgeChromium] = useState(false);
  // Blink engine detection
  const [isBlink, setIsBlink] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsOpera(
        (!!window.opr && !!opr.addons) ||
          !!window.opera ||
          navigator.userAgent.indexOf(' OPR/') >= 0
      );

      setIsFirefox(typeof InstallTrigger !== 'undefined');

      setIsSafari(
        /constructor/i.test(window.HTMLElement) ||
          (function (p) {
            return p.toString() === '[object SafariRemoteNotification]';
          })(
            !window.safari ||
              (typeof safari !== 'undefined' && safari.pushNotification)
          )
      );

      setIsIE(/* @cc_on!@ */ false || !!document.documentMode);

      setIsEdge(!isIE && !!window.StyleMedia);

      setIsChrome(!!window.chrome);

      setIsEdgeChromium(isChrome && navigator.userAgent.indexOf('Edg') !== -1);

      setIsBlink((isChrome || isOpera) && !!window.CSS);
    }
  }, []);

  return {
    isOpera,
    isFirefox,
    isSafari,
    isIE,
    isEdge,
    isEdgeChromium,
    isBlink,
    isChrome
  };
};

export default useBrowser;
