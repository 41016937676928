import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';

export const StyledCol = styled(Col)``;

export const StyledRow = styled(Row)`
  margin: ${({ theme }) => theme.utils.spacing(4)};
  ${({ theme }) => theme.breakpoints.md} {
    margin: 0;
    margin-bottom: ${({ theme }) => theme.utils.spacing(6)};
  }
`;
