import styled from 'styled-components';

export default styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  p {
    width: 250px;
  }
`;
