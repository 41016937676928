import { useTranslation } from 'react-i18next';
import useDate from '@hooks/useDate';

const useChart = ({ 
  data,
  mnemonic,
  index,
  isStandarizedTitle = true,
  fullNameMnemonic
}) => {
  const { t } = useTranslation();
  const dateFormatter = useDate(true);

  const getDateFormatter =
    (format = 'HH:mm') =>
    (date) =>
      dateFormatter(date).format(format);

  let selectedData = [];
  if (isStandarizedTitle) {
    selectedData = data.find((row) =>
      mnemonic ? mnemonic === row.mnemonic : index === row.index
    )
  } else {
    selectedData = data.find((row) => 
      fullNameMnemonic == row.mnemonic
    );
  }

  return {
    t,
    getDateFormatter,
    selectedData
  };
};

export default useChart;
