import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyledNovelty = styled(motion.div)`
  height: 291px;
  position: relative;
  padding: ${({ theme }) => theme.utils.spacing(8)};
  font-family: ${({ theme }) => theme.font.family.primary};
  ${({ theme }) => theme.breakpoints.md} {
    margin: 0;
  }
`;

export const StyledHeaderInformation = styled.div`
  align-items: center;
`;
