import styled from 'styled-components';

export default styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > button {
    margin: ${({ theme }) => theme.utils.spacing(0, 5)};
  }
`;
