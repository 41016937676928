import { useMemo } from 'react';

const useColumnFields = (data, ignoreKeys = []) => {
  const columnFields = useMemo(
    () =>
      data && data.length > 0
        ? Object.keys(data[0]).filter((key) => !ignoreKeys.includes(key))
        : [],
    [data, ignoreKeys]
  );
  return columnFields;
};

export default useColumnFields;
