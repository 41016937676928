import moment from 'moment';
import { Typography } from '@bvcco/bvc-digital-market-component-library';

const getFormattedDate = (date, props) => (
  <>
    <Typography type='paragraph3' color='primary' colorType='light' {...props}>
      {moment(date).format(`DD/MM/YYYY${props.isInvestigation ? '' : ' - LT'}`)}
    </Typography>
  </>
);

export default getFormattedDate;
