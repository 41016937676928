import styled, { css } from 'styled-components';

const getActiveStyles = ({ color, colorType, theme, showIndicator }) => css`
  border: 2px solid ${theme.colors.border.active.normal};
  box-shadow: 0px -2px ${theme.colors.bg[color][colorType]};

  ${showIndicator &&
  css`
    ${theme.breakpoints.md} {
      &:after {
        content: '';
        position: absolute;
        top: ${theme.utils.spacing(5.5)};
        left: 0;
        height: 16px;
        width: 4px;
        background: ${theme.colors.bg.active.normal};
        border-radius: 0 ${theme.borderRadius.medium}
          ${theme.borderRadius.medium} 0;
      }
    }
  `};
`;

export default styled.div`
  position: relative;
  padding: ${({ theme, spacings }) => theme.utils.spacing(...spacings)};
  background-color: ${({ color, colorType, theme }) =>
    theme.colors.bg[color][colorType]};
  border-radius: ${({ rounded, theme }) =>
    rounded ? theme.borderRadius.medium : theme.borderRadius.none};

  &:hover {
    cursor: ${({ selectable }) => (selectable ? 'pointer' : 'default')};
  }

  ${({ selected, ...props }) => selected && getActiveStyles(props)}
`;
