import styled from 'styled-components';
import { Table } from '@bvcco/bvc-digital-market-component-library';

export const StyledFooterTable = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.utils.spacing(0, 3, 3, 3)};
  border-top: 1px solid ${({ theme }) => theme.colors.separator.primary.dark};
  ${({ theme }) => theme.breakpoints.md} {
    padding: ${({ theme }) => theme.utils.spacing(2, 2, 2, 4)};
  }
`;

export const StyledTableField = styled(Table.Field)`
  display: flex;
  max-width: 80px;
`;
export const StyledVariationContainer = styled.div`
  margin: auto ${({ theme }) => theme.utils.spacing(2)};
`;
export const StyledFooterValues = styled.div`
  width: 50%;
  > * {
    > * {
      text-align: center;
    }
    text-align: center;
    margin-bottom: ${({ theme }) => theme.utils.spacing(2)};
  }
  ${({ theme }) => theme.breakpoints.md} {
    margin: ${({ theme }) => theme.utils.spacing(5, 0, 3, 0)};
    width: 100%;
    display: flex;
    > * {
      text-align: left;
      > * {
        text-align: left;
      }
      width: 50%;
    }
  }
`;

export const StyledFooterTexts = styled.div`
  width: 50%;
  text-align: center;
  margin: ${({ theme }) => theme.utils.spacing(6, 0, 1, 0)};
  ${({ theme }) => theme.breakpoints.md} {
    margin: 0;
    display: flex;
    text-align: left;
    padding-bottom: ${({ theme }) => theme.utils.spacing(5)};
    > div:first-of-type {
      width: 52%;
    }
    > div:last-of-type {
      width: 48%;
    }
  }
`;

export const StyledButtonContainer = styled.div`
  width: 50%;
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  ${({ theme }) => theme.breakpoints.md} {
    margin: 0;
    padding-top: ${({ theme }) => theme.utils.spacing(5)};
  }
`;

export const StyledImageContainer = styled.div`
  padding: ${({ theme }) => theme.utils.spacing(2, 0, 3, 0)};
  ${({ theme }) => theme.breakpoints.md} {
    padding: 0;
    > img {
      max-width: 80px;
      margin-left: ${({ theme }) => theme.utils.spacing(1)};
    }
  }
`;
