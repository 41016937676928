/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import {
  Button,
  Typography,
  VariationValue,
  Table as TableLibrary
} from '@bvcco/bvc-digital-market-component-library';

import { themes } from '@config/constants';
import useConfig from '@hooks/useConfig';

import Link from '@components/Link/Link';
import useBrowser from '@hooks/useBrowser';
import { formatDecimal } from '@utils/numbers';
import { useMobile } from '@hooks/useBreakpoint';
import {
  StyledBottomText,
  StyledTableField,
  StyledFooterTable,
  StyledTableContainer
} from '@components/MarketLevel1/Tabs/Table.styled';
import useColumnFields from '@hooks/local-market/useColumnFields';

const Table = ({
  buttonContent,
  buttonTexts,
  columnNames,
  data,
  renderedMobileContent,
  tradedVolume,
  selectedRows,
  selectedTab,
  updateSelectedRows
}) => {
  const isMobile = useMobile();
  const { isSafari } = useBrowser();
  const [{ theme }] = useConfig();
  const formatData = useMemo(
    () =>
      data.map((row, index) => ({
        tableId: row.mnemonic + index,
        mnemonic: row.mnemonic,
        volume: formatDecimal(row.volume, {
          separator: ',',
          decimal: '.',
          decimals: 2
        }),
        lastPrice: row.lastPrice
          ? formatDecimal(row.lastPrice, {
              separator: ',',
              decimal: '.',
              decimals: 2
            })
          : row.lastPrice,
        variation: row.variation
      })),
    [data]
  );
  const fieldNames = useColumnFields(formatData, ['tableId']);

  useEffect(() => {
    if (!isMobile && selectedRows.length > 0) {
      const selectedRow = selectedRows[0];
      const selectedRowInData = formatData.find(
        ({ mnemonic }) => mnemonic === selectedRow?.mnemonic
      );
      if (!selectedRowInData) {
        updateSelectedRows(formatData[0] ? [formatData[0]] : []);
      }
    }
  }, [formatData]);

  useEffect(() => {
    if (!isMobile && formatData.length > 0) {
      updateSelectedRows([formatData[0]]);
    }
  }, [selectedTab, updateSelectedRows]);

  useEffect(() => {
    if (!isMobile && selectedRows.length === 0 && formatData.length > 0) {
      updateSelectedRows([formatData[0]]);
    }
  }, [formatData, updateSelectedRows]);

  const columns = useMemo(
    () =>
      columnNames.map(({ description }, index) => {
        const column = {
          title: description,
          field: fieldNames[index]
        };
        if (index === 0) {
          column.render = (row) => (
            <TableLibrary.Field
              color='quaternary'
              type='caption'
              fontWeight='900'
            >
              {row[fieldNames[index]]}
            </TableLibrary.Field>
          );
        }
        if (index === columnNames.length - 2) {
          column.render = ({ variation }) => (
            <StyledTableField
              title={`${variation.variation}%`}
              color='quaternary'
              type='paragraph3'
            >
              {variation.variation}%
            </StyledTableField>
          );
        }
        if (index === columnNames.length - 1) {
          column.render = ({ variation }) => (
            <StyledTableField
              title={`${variation.variation}%`}
              color='quaternary'
              type='paragraph3'
            >
              <VariationValue value={variation.variation} />
            </StyledTableField>
          );
        }
        return column;
      }),
    [columnNames, formatData]
  );

  return (
    <StyledTableContainer>
      <TableLibrary
        alignTitle='center'
        bodyBgColor='transparent'
        bodyColor='quaternary'
        colorDivider='tabs'
        columns={columns}
        data={formatData}
        expandable={isMobile}
        fullWidth
        textAlign='center'
        headBgColor='transparent'
        headColor='infoTabs'
        headColorType={theme === themes.DARK ? 'dark' : 'normal'}
        headFontWeight='900'
        tableWidth='98%'
        updateSelectedRows={updateSelectedRows}
        renderExpandedContent={() => renderedMobileContent}
        selectable
        selectedRows={selectedRows}
        selectType={isMobile ? 'singleToggle' : 'single'}
        spacingY={2}
        isSafari={isSafari}
      />
      <StyledFooterTable>
        <StyledBottomText>
          <Typography color='infoGeneral' colorType='normal' type='caption'>
            {buttonTexts[0].description}{' '}
            <Typography
              color='infoGeneral'
              colorType='normal'
              type='caption'
              as='span'
              fontWeight='900'
            >
              {formatDecimal(tradedVolume, {
                separator: ',',
                decimal: '.',
                decimals: 2
              })}
            </Typography>
          </Typography>
          <Typography
            color='infoGeneral'
            colorType='normal'
            fontWeight='bold'
            type='caption'
          >
            {buttonTexts[1].description}
          </Typography>
        </StyledBottomText>
        <Button
          color='primary'
          textProps={{
            color: 'primary',
            colorType: 'dark',
            fontWeight: 'bold',
            fontFamily: 'secondary',
            fontSize: 'caption'
          }}
          fullWidth={false}
          hoverColor='quinary'
          hoverColorType='light'
        >
          <Link to={buttonContent?.url}>{buttonContent.text}</Link>
        </Button>
      </StyledFooterTable>
    </StyledTableContainer>
  );
};

Table.propTypes = {
  buttonContent: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string
  }).isRequired,
  buttonTexts: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string
    })
  ).isRequired,
  columnNames: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderedMobileContent: PropTypes.node.isRequired,
  tradedVolume: PropTypes.number.isRequired,
  selectedTab: PropTypes.number.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.object).isRequired,
  tabsHeaderHeight: PropTypes.number.isRequired,
  updateSelectedRows: PropTypes.func.isRequired
};

export default Table;
