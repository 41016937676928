import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@bvcco/bvc-digital-market-component-library';

import { formatDecimal } from '@utils/numbers';
import useDelay from '@hooks/local-market/useDelay';
import { StyledRow, StyledCol } from '../../Header.styled';

const Header = ({ delay, mnemonic, previousClosing, marketIsOpen }) => {
  const { t } = useTranslation();
  const delayText = useDelay(delay);

  return (
    <StyledRow>
      <StyledCol xs={6}>
        {mnemonic && (
          <>
            <Typography color='tertiary' type='caption' fontWeight='900' as='p'>
              {mnemonic?.toUpperCase()}
            </Typography>
            <Typography color='quaternary' type='caption' as='p'>
              {t('referencePrice')}:{' '}
              {formatDecimal(previousClosing, { separator: ',', decimals: 2 })}
            </Typography>
          </>
        )}
      </StyledCol>
      <StyledCol xs={6}>
        <Typography
          textAlign='right'
          fontWeight='900'
          color={marketIsOpen ? 'success' : 'danger'}
          type='caption'
          as='p'
        >
          {marketIsOpen ? t('openMarket') : t('closedMarket')}
        </Typography>
        <Typography textAlign='right' color='tertiary' type='caption' as='p'>
          {delayText}
        </Typography>
      </StyledCol>
    </StyledRow>
  );
};

Header.propTypes = {
  delay: PropTypes.string.isRequired,
  marketIsOpen: PropTypes.bool.isRequired,
  mnemonic: PropTypes.string.isRequired,
  previousClosing: PropTypes.number.isRequired
};

export default Header;
