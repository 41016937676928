/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Typography,
  VariationValue,
  Table as TableLibrary
} from '@bvcco/bvc-digital-market-component-library';

import { themes } from '@config/constants';
import useConfig from '@hooks/useConfig';

import {
  StyledTableField,
  StyledFooterTexts,
  StyledFooterTable,
  StyledFooterValues,
  StyledImageContainer,
  StyledButtonContainer
} from './Table.styled';
import Link from '@components/Link/Link';
import IconSetIcap from '@assets/icons/logoSetICAP.svg';
import useBrowser from '@hooks/useBrowser';
import { formatDecimal, generalFormatDecimal } from '@utils/numbers';
import { useMobile } from '@hooks/useBreakpoint';
import useColumnFields from '@hooks/local-market/useColumnFields';
import { StyledTableContainer } from '@components/MarketLevel1/Tabs/Table.styled';

const Table = ({
  buttonContent,
  buttonTexts,
  columnNames,
  data,
  renderedMobileContent,
  tradedVolume,
  registeredVolume,
  selectedRows,
  selectedTab,
  updateSelectedRows
}) => {
  const [{ theme }] = useConfig();
  const { t } = useTranslation();
  const isMobile = useMobile();
  const { isSafari } = useBrowser();
  const formatData = useMemo(
    () =>
      data.map((row, index) => ({
        tableId: row.mnemonic + index,
        lastPrice: row.lastPrice,
        averagePrice: row.averagePrice,
        variation: row.variation
      })),
    [data]
  );
  const fieldNames = useColumnFields(formatData, [
    'tableId',
    'previousClosing'
  ]);

  useEffect(() => {
    if (!isMobile && selectedRows.length > 0) {
      const selectedRow = selectedRows[0];
      const selectedRowInData = formatData.find(
        ({ mnemonic }) => mnemonic === selectedRow?.mnemonic
      );
      if (!selectedRowInData) {
        updateSelectedRows(formatData[0] ? [formatData[0]] : []);
      }
    }
  }, [formatData]);

  useEffect(() => {
    if (!isMobile && formatData.length > 0) {
      updateSelectedRows([formatData[0]]);
    }
  }, [selectedTab, updateSelectedRows]);

  useEffect(() => {
    if (!isMobile && selectedRows.length === 0 && formatData.length > 0) {
      updateSelectedRows([formatData[0]]);
    }
  }, [formatData, updateSelectedRows]);

  const columns = useMemo(
    () =>
      columnNames.map(({ description }, index) => {
        const column = {
          title: description,
          field: fieldNames[index]
        };

        if (index === columnNames.length - 2) {
          column.render = ({ variation }) => (
            <StyledTableField
              title={`${variation}%`}
              color='quaternary'
              type='paragraph3'
            >
              {variation}%
            </StyledTableField>
          );
        }
        if (index === columnNames.length - 1) {
          column.render = ({ variation }) => (
            <StyledTableField
              title={`${variation}%`}
              color='quaternary'
              type='paragraph3'
            >
              <VariationValue value={variation} />
            </StyledTableField>
          );
        } else {
          column.render = (row) => (
            <TableLibrary.Field color='quaternary' type='paragraph3'>
              <>
                {fieldNames[index] !== 'variation' ? (
                  <>
                    {formatDecimal(row[fieldNames[index]], {
                      separator: ',',
                      decimal: '.',
                      decimals: 2
                    })}
                  </>
                ) : (
                  <>
                    {generalFormatDecimal(row[fieldNames[index]], {
                      separator: ',',
                      decimal: '.',
                      decimals: 2
                    })}
                  </>
                )}
              </>
            </TableLibrary.Field>
          );
        }
        return column;
      }),
    [columnNames, formatData]
  );

  return (
    <StyledTableContainer>
      <TableLibrary
        bodyBgColor='transparent'
        bodyColor='quaternary'
        colorDivider='tabs'
        columns={columns}
        data={formatData}
        expandable={isMobile}
        fullWidth
        headBgColor='transparent'
        headColor='infoTabs'
        headColorType={theme === themes.DARK ? 'dark' : 'normal'}
        headFontWeight='900'
        tableWidth='98%'
        updateSelectedRows={updateSelectedRows}
        renderExpandedContent={() => renderedMobileContent}
        selectable
        selectedRows={selectedRows}
        selectType={isMobile ? 'singleToggle' : 'single'}
        spacingY={2}
        isSafari={isSafari}
      />
      <StyledFooterTable>
        <StyledFooterValues>
          <Typography
            color='infoGeneral'
            colorType='normal'
            type='caption'
            as='p'
          >
            {buttonTexts[0].description}{' '}
            <Typography
              color='infoGeneral'
              colorType='normal'
              type='caption'
              as='p'
              fontWeight='900'
            >
              {`${formatDecimal(tradedVolume, {
                separator: ',',
                decimal: '.',
                decimals: 2
              })} ${t('thousandsOfDollars')}`}
            </Typography>
          </Typography>
          <Typography
            color='infoGeneral'
            colorType='normal'
            type='caption'
            as='p'
          >
            {buttonTexts[1].description}{' '}
            <Typography
              color='infoGeneral'
              colorType='normal'
              type='caption'
              as='p'
              fontWeight='900'
            >
              {`${formatDecimal(registeredVolume, {
                separator: ',',
                decimal: '.'
              })} ${t('millionsOfPesos')}`}
            </Typography>
          </Typography>
        </StyledFooterValues>
        {buttonTexts[2] && (
          <StyledFooterTexts>
            <Typography color='infoGeneral' colorType='normal' type='caption'>
              {buttonTexts[2].description}
            </Typography>
            <StyledImageContainer>
              <Link
                to={buttonTexts[2].logo?.url}
                key={buttonTexts[2].logo?.icon?.fileName}
              >
                {theme === themes.DARK ? (
                  <img
                    src={buttonTexts[2].logo.icon.url}
                    alt={buttonTexts[2].logo.icon.fileName}
                  />
                ) : (
                  <IconSetIcap />
                )}
              </Link>
            </StyledImageContainer>
          </StyledFooterTexts>
        )}

        <StyledButtonContainer>
          <Button
            color='primary'
            textProps={{
              color: 'primary',
              colorType: 'dark',
              fontWeight: 'bold',
              fontFamily: 'secondary',
              fontSize: 'caption'
            }}
            size='large'
            fullWidth={isMobile}
            hoverColor='quinary'
            hoverColorType='light'
          >
            <Link to={buttonContent?.url}>{buttonContent.text}</Link>
          </Button>
        </StyledButtonContainer>
      </StyledFooterTable>
    </StyledTableContainer>
  );
};

Table.propTypes = {
  buttonContent: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string
  }).isRequired,
  buttonTexts: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string
    })
  ).isRequired,
  columnNames: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderedMobileContent: PropTypes.node.isRequired,
  tradedVolume: PropTypes.number.isRequired,
  registeredVolume: PropTypes.number.isRequired,
  selectedTab: PropTypes.number.isRequired,
  tabsHeaderHeight: PropTypes.number.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateSelectedRows: PropTypes.func.isRequired
};

export default Table;
