import PropTypes from 'prop-types';
import { FancyLine } from '@bvcco/bvc-digital-market-component-library';

import StyledListItem from './ListItem.styled';
import { childrenProps, colorTypeProps } from '@config/common-propTypes';

const ListItem = ({
  children,
  color,
  colorSeparator,
  colorSeparatorType,
  colorType,
  onClick,
  rounded,
  selectable,
  selected,
  showIndicator,
  spacings,
  ...rest
}) => (
  <StyledListItem
    color={color}
    colorType={colorType}
    onClick={onClick}
    rounded={rounded}
    selectable={selectable}
    selected={selected}
    showIndicator={showIndicator}
    spacings={spacings}
    {...rest}
  >
    {children}
    {!selected && (
      <FancyLine
        height='1px'
        lineColor={colorSeparator}
        lineColorType={colorSeparatorType}
        width='100%'
        withoutMargin
        withSquare={false}
      />
    )}
  </StyledListItem>
);

ListItem.propTypes = {
  children: childrenProps.isRequired,
  color: PropTypes.string,
  colorSeparator: PropTypes.string,
  colorSeparatorType: colorTypeProps,
  colorType: colorTypeProps,
  onClick: PropTypes.func,
  rounded: PropTypes.bool,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  showIndicator: PropTypes.bool,
  spacings: PropTypes.arrayOf(PropTypes.number)
};

ListItem.defaultProps = {
  color: 'transparent',
  colorSeparator: 'primary',
  colorSeparatorType: 'dark',
  colorType: 'normal',
  onClick: undefined,
  rounded: false,
  selectable: false,
  selected: false,
  showIndicator: false,
  spacings: [5]
};

export default ListItem;
