/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import {
  Button,
  Typography,
  VariationValue,
  Table as TableLibrary
} from '@bvcco/bvc-digital-market-component-library';

import { themes } from '@config/constants';
import useConfig from '@hooks/useConfig';

import Link from '@components/Link/Link';
import useBrowser from '@hooks/useBrowser';
import { formatDecimal } from '@utils/numbers';
import { properties } from '@config/constants';
import { useMobile } from '@hooks/useBreakpoint';
import {
  StyledBottomText,
  StyledTableField,
  StyledFooterTable,
  StyledCenteredField,
  StyledTableContainer
} from '@components/MarketLevel1/Tabs/Table.styled';
import useColumnFields from '@hooks/local-market/useColumnFields';
import useFixedRentTable from '@hooks/local-market/useFixedRentTable';

const CDT = 'CDT';

const capitalizeFirstLetter = (string) => {
  if (string === CDT) {
    return string;
  }

  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const Table = ({
  buttonContent,
  buttonTexts,
  columnNames,
  data,
  renderedMobileContent,
  tradedVolume,
  registeredVolume,
  selectedRows,
  selectedTab,
  updateSelectedRows,
  currentTab,
  // eslint-disable-next-line no-unused-vars
  hasToDivideVolumeBy1M
}) => {
  const isMobile = useMobile();
  const [{ theme }] = useConfig();
  const { isSafari } = useBrowser();
  const formatData = useFixedRentTable(data, currentTab);
  const fieldNames = useColumnFields(formatData, ['tableId', 'referenceRate']);
  const isRfPub = currentTab === properties.rf.most_traded_public_debt;

  useEffect(() => {
    if (!isMobile && selectedRows.length > 0) {
      const selectedRow = selectedRows[0];
      const selectedRowInData = formatData.find(({ titleClass, mnemonic }) =>
        mnemonic
          ? mnemonic === selectedRow?.mnemonic
          : titleClass === selectedRow?.titleClass
      );
      if (!selectedRowInData) {
        updateSelectedRows(formatData[0] ? [formatData[0]] : []);
      }
    }
  }, [formatData]);

  useEffect(() => {
    if (!isMobile && formatData.length > 0) {
      updateSelectedRows([formatData[0]]);
    }
  }, [selectedTab, updateSelectedRows]);

  useEffect(() => {
    if (!isMobile && selectedRows.length === 0 && formatData.length > 0) {
      updateSelectedRows([formatData[0]]);
    }
  }, [formatData, updateSelectedRows]);

  const columns = useMemo(
    () =>
      columnNames.map(({ description }, index) => {
        const column = {
          title: description,
          field: fieldNames[index]
        };
        if (index === 0) {
          column.render = (row) => (
            <TableLibrary.Field
              color='quaternary'
              type='caption'
              fontWeight='900'
            >
              {row[fieldNames[index]]}
            </TableLibrary.Field>
          );
        }
        if (!isRfPub && index === 0) {
          column.render = ({ titleClass }) => {
            return (
              <StyledCenteredField
                fontWeight='900'
                title={titleClass}
                color='quaternary'
                type='paragraph3'
              >
                {capitalizeFirstLetter(titleClass)}
              </StyledCenteredField>
            );
          };
        }
        if (!isRfPub && index === 2) {
          column.render = ({ numberOfOperations }) => (
            <StyledCenteredField
              title={numberOfOperations}
              color='quaternary'
              type='paragraph3'
            >
              {numberOfOperations}
            </StyledCenteredField>
          );
        }
        if (index === columnNames.length - 2 && isRfPub) {
          column.render = ({ variation }) => (
            <StyledTableField
              title={`${variation}`}
              color='quaternary'
              type='paragraph3'
            >
              {variation}
            </StyledTableField>
          );
        }
        if (index === columnNames.length - 1 && isRfPub) {
          column.render = ({ variation }) => (
            <StyledTableField
              title={`${variation}`}
              color='quaternary'
              type='paragraph3'
            >
              <VariationValue value={variation} invertColors />
            </StyledTableField>
          );
        }
        return column;
      }),
    [columnNames, formatData]
  );

  return (
    <StyledTableContainer
      centered={!isRfPub}
      fixedHeight={isRfPub}
      withMargin={isMobile && !isRfPub}
    >
      <TableLibrary
        alignTitle='center'
        bodyBgColor='transparent'
        bodyColor='quaternary'
        colorDivider='tabs'
        columns={columns}
        data={formatData}
        expandable={isMobile}
        fullWidth
        textAlign='center'
        headBgColor='transparent'
        headColor='infoTabs'
        headColorType={theme === themes.DARK ? 'dark' : 'normal'}
        headFontWeight='900'
        tableWidth='98%'
        updateSelectedRows={updateSelectedRows}
        renderExpandedContent={() => renderedMobileContent}
        selectable={isRfPub}
        selectedRows={selectedRows}
        selectType={isMobile ? 'singleToggle' : 'single'}
        isSafari={isSafari}
      />
      {!isRfPub && isMobile && renderedMobileContent}
      <StyledFooterTable>
        <StyledBottomText>
          <Typography color='infoGeneral' colorType='normal' type='caption'>
            {buttonTexts[0].description}{' '}
            <Typography
              color='infoGeneral'
              colorType='normal'
              type='caption'
              as='span'
              fontWeight='900'
            >
              {formatDecimal(tradedVolume || 0, {
                separator: ',',
                decimal: '.',
                decimals: 2
              })}
            </Typography>
          </Typography>
          <Typography color='infoGeneral' colorType='normal' type='caption'>
            {buttonTexts[1].description}{' '}
            <Typography
              color='infoGeneral'
              colorType='normal'
              type='caption'
              as='span'
              fontWeight='900'
            >
              {formatDecimal(registeredVolume || 0, {
                separator: ',',
                decimal: '.',
                decimals: 2
              })}
            </Typography>
          </Typography>
          {buttonTexts[2] && (
            <Typography
              color='infoGeneral'
              colorType='normal'
              fontWeight='bold'
              type='caption'
            >
              {buttonTexts[2].description}
            </Typography>
          )}
        </StyledBottomText>
        <Button
          color='primary'
          textProps={{
            color: 'primary',
            colorType: 'dark',
            fontWeight: 'bold',
            fontFamily: 'secondary',
            fontSize: 'caption'
          }}
          hoverColor='quinary'
          hoverColorType='light'
          fullWidth={false}
        >
          <Link to={buttonContent?.url}>{buttonContent.text}</Link>
        </Button>
      </StyledFooterTable>
    </StyledTableContainer>
  );
};

Table.propTypes = {
  buttonContent: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string
  }).isRequired,
  buttonTexts: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string
    })
  ).isRequired,
  columnNames: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderedMobileContent: PropTypes.node.isRequired,
  tradedVolume: PropTypes.number.isRequired,
  registeredVolume: PropTypes.number.isRequired,
  selectedTab: PropTypes.number.isRequired,
  tabsHeaderHeight: PropTypes.number.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateSelectedRows: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
  hasToDivideVolumeBy1M: PropTypes.bool
};

Table.defaultProps = {
  hasToDivideVolumeBy1M: true
};

export default Table;
