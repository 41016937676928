import React from 'react';
import PropTypes from 'prop-types';
import { StyledContainerBanner } from './HiddenGraphicBanner.styled';

const HiddenGraphicBanner = ({ description, imageUrl, alt }) => {
  return (
    <StyledContainerBanner>
      <div>
        <img src={imageUrl} alt={alt ?? 'Image Description'} /> 
      </div>
      <div>
        <p className='description-text'>{description}</p>
      </div>
    </StyledContainerBanner>
  )
}

HiddenGraphicBanner.propTypes = {
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired
}

export default HiddenGraphicBanner