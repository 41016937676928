import { Button, FancyLine } from '@bvcco/bvc-digital-market-component-library';

import StyledButtonContainer from './ButtonLines.styled';

const ButtonLines = (props) => (
  <StyledButtonContainer>
    <FancyLine
      lineColor='secondary'
      withSquare={false}
      width={{ xs: '30%', sm: '40%', md: '110%', lg: '110%' }}
      height='1px'
      withoutMargin
    />
    <Button {...props} hoverColor='btnMk2Footer' hoverColorType='light' />
    <FancyLine
      lineColor='secondary'
      withSquare={false}
      width={{ xs: '30%', sm: '40%', md: '110%', lg: '110%' }}
      height='1px'
      withoutMargin
    />
  </StyledButtonContainer>
);

export default ButtonLines;
