import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';

export const StyledChartContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledCol = styled(Col)``;

export const StyledRow = styled(Row)`
  padding: ${({ theme }) => theme.utils.spacing(4, 4, 0, 4)};
  ${({ theme }) => theme.breakpoints.md} {
    padding: ${({ theme }) => theme.utils.spacing(6, 4, 0, 0)};
  }
`;
