import styled from 'styled-components';
import ListItem from '@components/ListItem/ListItem';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledListItem = styled(ListItem)`
  margin: ${({ theme }) => theme.utils.spacing(4)};
`;

export const StyledDate = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: ${({ theme }) => theme.utils.spacing(6.2, 4, 0, 4)};

  ${({ theme }) => theme.breakpoints.md} {
    display: none;
  }
`;

export const StyledEvent = styled.div`
  .type {
    margin-left: auto;
    margin-bottom: ${({ theme }) => theme.utils.spacing(2)};
  }
  .hour {
    display: block;
    margin-top: ${({ theme }) => theme.utils.spacing(12)};
  }
  ${({ theme }) => theme.breakpoints.md} {
    .type {
      position: absolute;
      bottom: ${({ theme }) => theme.utils.spacing(2)};
      right: ${({ theme }) => theme.utils.spacing(3)};
    }
  }
`;
