import PropTypes from 'prop-types';
import {
  LightWeightCharts,
  LightWeightChartsN
} from '@bvcco/bvc-digital-market-component-library';

import Header from '../Header/Header';
import { StyledRow, StyledCol } from '../../Chart.styled';
import useChart from '@hooks/local-market/level-1/useChart';
import { TOOLTIP_CHART_DIMENSIONS } from '@config/constants';

const Chart = ({ data, headerProps, index, xAxisShowHour, isMoneyMarket }) => {
  const { t, getDateFormatter } = useChart({
    data,
    index
  });

  const manageRangeAndSeries = () => {
    const indexAux = data.find((item) => item.index === index);
    if (data.length > 0 && index && indexAux) {
      const lastArray = [
        {
          type: 'linear',
          content: {
            data: indexAux.points.map((item) => ({
              time: parseInt(item.xAxis),
              value: item.yAxis
            }))
          }
        }
      ];
      return {
        dataAux: lastArray,
        fromToDate: last50Elements(lastArray)
      };
    }

    return {
      dataAux: [],
      fromToDate: {
        from: 0,
        to: 0
      }
    };
  };

  const last50Elements = (objectFilter) => {
    const fromToDate = {};
    const firstSerie = objectFilter[0].content.data;
    if (firstSerie.length > 50) {
      fromToDate.from = parseInt(firstSerie[firstSerie.length - 50].time);
      fromToDate.to = parseInt(firstSerie[firstSerie.length - 1].time);
      return fromToDate;
    }

    return {
      from: 0,
      to: 0
    };
  };
  const { dataAux, fromToDate } = manageRangeAndSeries();
  return (
    <>
      {index && data.find((item) => item.index === index) ? (
        <StyledRow>
          <StyledCol xs={false} md={12}>
            <Header {...headerProps} />
          </StyledCol>
          <StyledCol xs={12}>
            <LightWeightChartsN
              dateFormat={getDateFormatter(xAxisShowHour ? 'HH:mm' : 'DD MMM')}
              tooltipConfig={{
                show: true,
                bgColor: 'chartTooltip',
                titleTop: isMoneyMarket ? t('date') : t('hour'),
                titleBottom: t('valueToday'),
                dimensions: TOOLTIP_CHART_DIMENSIONS.SMALL,
                seriesPosition: 0
              }}
              data={dataAux}
              fromToDate={fromToDate}
              priceLine={headerProps.previousClosing}
            />
          </StyledCol>
        </StyledRow>
      ) : null}
    </>
  );
};

Chart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  headerProps: PropTypes.shape(Header.propTypes).isRequired,
  index: PropTypes.string.isRequired,
  xAxisShowHour: PropTypes.bool.isRequired,
  isMoneyMarket: PropTypes.bool.isRequired
};

export default Chart;
