import PropTypes from 'prop-types';
import { Recharts } from '@bvcco/bvc-digital-market-component-library';

import useChart from '@hooks/local-market/level-1/useChart';
import useFixedRentTable from '@hooks/local-market/useFixedRentTable';

import Header from '../Header/Header';
import { StyledRow, StyledCol } from '../../Chart.styled';
import HiddenGraphicBanner from '../../HiddenGraphicBanner';

const MAX_CHARTS_LABEL_TOTAL_LENGTH = 40;
const CDT = 'CDT';

const capitalizeFirstLetter = (string) => {
  if (string === CDT) {
    return string;
  }

  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const splitLongWords = (nString) => {
  const doSplit = (word) => {
    const splittedWords = word.split('.');
    return splittedWords.join(' ');
  };
  return nString.length > MAX_CHARTS_LABEL_TOTAL_LENGTH
    ? `${nString.substring(0, MAX_CHARTS_LABEL_TOTAL_LENGTH)} ...`
    : doSplit(nString);
};

const HorizontalChart = ({ data, headerProps, mnemonic, currentTab, hideGraphicLevelOne }) => {
  const { isMobile } = useChart({ data, mnemonic });
  const formatData = useFixedRentTable(data, currentTab);

  const formattedData = formatData?.map((nData) => ({
    name: capitalizeFirstLetter(splitLongWords(nData.titleClass)),
    volume: parseFloat(nData.volume.replace(/,/g, ''))
  }));

  return (
    <StyledRow>
      <StyledCol xs={false} md={12}>
        <Header {...headerProps} />
      </StyledCol>
      <StyledCol xs={12}>
        <Recharts
          bar={{
            maxBarSize: 60
          }}
          container={{
            height: isMobile ? 300 : 340
          }}
          chart={{
            layout: 'vertical',
            margin: { top: 0, right: 0, left: 0, bottom: 0 }
          }}
          data={formattedData}
          dataKey='volume'
          tickTypoStylesX={{
            color: 'info',
            colorType: 'dark',
            fontWeight: 900
          }}
          tickTypoStylesY={{
            color: 'info',
            colorType: 'dark',
            textTransform: 'capitilize',
            fontWeight: 900
          }}
          yAxis={{ width: 80, type: 'category', dataKey: 'name' }}
        />
      </StyledCol>
    </StyledRow>
  );
};

HorizontalChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  headerProps: PropTypes.shape(Header.propTypes).isRequired,
  mnemonic: PropTypes.string.isRequired,
  currentTab: PropTypes.string.isRequired,
  hideGraphicLevelOne: PropTypes.object.isRequired
};

export default HorizontalChart;
