import PropTypes from 'prop-types';
import {
  Typography,
  LightWeightChartsN
} from '@bvcco/bvc-digital-market-component-library';

import Header from '../Header/Header';
import { formatDecimal } from '@utils/numbers';
import { StyledRow, StyledCol } from '../../Chart.styled';
import useChart from '@hooks/local-market/level-1/useChart';
import { TOOLTIP_CHART_DIMENSIONS } from '@config/constants';
import HiddenGraphicBanner from '../../HiddenGraphicBanner';

const Chart = ({
  data,
  headerProps,
  mnemonic,
  hideGraphicLevelOne,
  fullNameMnemonic = ''
}) => {
  const isStandarizedTitle = !fullNameMnemonic.includes('-');

  const { t, selectedData, getDateFormatter } = useChart({
    data,
    mnemonic,
    isStandarizedTitle,
    fullNameMnemonic
  });
  const renderTooltip = (content) => {
    if (!selectedData) {
      return null;
    }
    const point = selectedData?.points.find(
      ({ xAxis }) => xAxis === content.originalDate
    );
    return (
      <>
        <Typography
          color='secondaryChar'
          colorType='light'
          fontWeight='bold'
          type='caption'
        >
          {t('hour')}
        </Typography>
        <Typography
          color='charTooltipValue'
          colorType='light'
          type='paragraph3'
        >
          {content.date}
        </Typography>
        <Typography
          color='secondaryChar'
          colorType='light'
          fontWeight='bold'
          type='caption'
        >
          {t('rateOrPrice')}
        </Typography>
        <Typography
          color='charTooltipValue'
          colorType='light'
          type='paragraph3'
        >
          {formatDecimal(content.value, { separator: '.', decimals: 3 })}
        </Typography>
        <Typography
          color='secondaryChar'
          colorType='light'
          fontWeight='bold'
          type='caption'
        >
          {t('volume')}*
        </Typography>
        <Typography
          color='charTooltipValue'
          colorType='light'
          type='paragraph3'
        >
          {formatDecimal(point?.y2Axis || 0, { decimals: 2 })}
        </Typography>
      </>
    );
  };
  const manageRangeAndSeries = () => {
    const mnemonicFound = data.find((item) =>
      isStandarizedTitle
        ? item.mnemonic === mnemonic
        : item.mnemonic === fullNameMnemonic
    );

    if (data.length > 0 && mnemonic && mnemonicFound) {
      const lastArray = [
        {
          type: 'linear',
          content: {
            data: mnemonicFound.points.map((item) => ({
              time: parseInt(item.xAxis),
              value: item.y1Axis
            }))
          }
        },
        {
          type: 'histogram',
          content: {
            data: mnemonicFound.points.map((item) => ({
              time: parseInt(item.xAxis),
              value: item.y2Axis
            })),
            settings: {
              priceScaleId: 'left',
              color: '#344753'
            }
          }
        }
      ];
      return {
        dataAux: lastArray,
        fromToDate: last50Elements(lastArray)
      };
    }

    return {
      dataAux: [],
      fromToDate: []
    };
  };

  const last50Elements = (objectFilter) => {
    const fromToDate = {};
    const firstSerie = objectFilter[0].content.data;
    if (firstSerie.length > 50) {
      fromToDate.from = parseInt(firstSerie[firstSerie.length - 50].time);
      fromToDate.to = parseInt(firstSerie[firstSerie.length - 1].time);
      return fromToDate;
    }

    return {
      from: 0,
      to: 0
    };
  };
  const { dataAux, fromToDate } = manageRangeAndSeries();

  return (
    <StyledRow>
      <StyledCol xs={false} md={12}>
        <Header {...headerProps} mnemonic={mnemonic} />
      </StyledCol>
      <StyledCol xs={12}>
        {hideGraphicLevelOne?.active && (
          <HiddenGraphicBanner {...hideGraphicLevelOne} />
        )}
        {!hideGraphicLevelOne?.active && (
          <LightWeightChartsN
            dateFormat={getDateFormatter()}
            tooltipConfig={{
              show: true,
              bgColor: 'chartTooltip',
              dimensions: TOOLTIP_CHART_DIMENSIONS.MEDIUM_LARGE,
              renderContent: renderTooltip,
              seriesPosition: 0
            }}
            data={dataAux}
            fromToDate={fromToDate}
            priceLine={headerProps.referenceRate}
          />
        )}
      </StyledCol>
    </StyledRow>
  );
};

Chart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  headerProps: PropTypes.shape(Header.propTypes).isRequired,
  mnemonic: PropTypes.string.isRequired,
  hideGraphicLevelOne: PropTypes.object.isRequired,
  fullNameMnemonic: PropTypes.string
};

Chart.defaultProps = {
  fullNameMnemonic: ''
};

export default Chart;
