import styled, { css } from 'styled-components';
import { Table } from '@bvcco/bvc-digital-market-component-library';

export const StyledFooterTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify || 'space-between'};
  padding: ${({ theme }) => theme.utils.spacing(2, 2, 2, 8)};
  border-top: 1px solid ${({ theme }) => theme.colors.separator.primary.dark};
`;

export const StyledVariationText = styled.p`
  margin-right: ${({ theme }) => theme.utils.spacing(2)};
`;

export const StyledTableField = styled(Table.Field)`
  display: flex;
`;

export const StyledCenteredField = styled(Table.Field)`
  display: flex;
  justify-content: center !important;
`;

export const StyledTableContainer = styled.div`
  height: ${({ fixedHeight }) => (fixedHeight ? '500px' : 'auto')};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  & table {
    border: 3px solid ${({ theme }) => theme.colors.bg.localMarket.dark};
  }
  & table tr td p {
    justify-content: center !important;
  }
  & table tr td:last-child {
    padding: 0;
  }
  & table tr td:last-child > div {
    position: relative;
    left: ${({ theme }) => theme.utils.spacing(-1)};
    ${({ theme }) => theme.breakpoints.sm} {
      left: ${({ theme }) => theme.utils.spacing(-7)} !important;
    }
    ${({ theme }) => theme.breakpoints.md} {
      left: ${({ theme }) => theme.utils.spacing(-2)} !important;
    }
  }
  & table th span {
    justify-content: center;
  }

  ${({ centered }) =>
    centered
      ? css`
          table tr > th > div {
            display: flex;
            justify-content: center;
          }
          table td > div > p {
            display: flex;
            justify-content: center;
          }
        `
      : css`
          table td > div > p {
            display: flex;
            justify-content: space-between;
          }
        `}
  ${({ withMargin }) =>
    withMargin &&
    css`
      & > div {
        margin: ${({ theme }) => theme.utils.spacing(2, 0)};
      }
    `};

  ${({ theme }) => theme.breakpoints.md} {
    height: 400px;
  }
`;

export const StyledBottomText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
